import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, FormGroup, Alert, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import { useUser } from "../../../hooks/user";
import IndeterminateButton from "../../IndeterminateButton";
import UpdateEmail from "../UpdateEmail/UpdateEmail";
import { encode } from "base-64";
import {
    setUserInfo,
    setAssemblies,
    removeAssembly,
} from "../../../reducers/rootReducer";
import AssemblyProvider from "../../AssemblyProvider";
import { useTrackEvent } from "../../../hooks/useTrackEvent";

const Verify = ({ nextStep, username }) => {
    const dispatch = useDispatch();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const { execute, isLoading, error: verifyError } = useUser();
    const {
        execute: executeResendPassword,
        isLoading: isLoadingResetPassword,
        error: resendError,
    } = useUser();
    const registerForm = useForm({ mode: "onBlur" }); // Must set mode to all for it to validate on blur of all fields. This is to be able to enable/disable the submit button
    const { handleSubmit, setError, reset } = registerForm;
    const { trackGTM } = useTrackEvent();

    const resendCode = async () => {
        await executeResendPassword(`userID/password-establish`, {
            method: "POST",
            body: JSON.stringify({}),
        });
    };

    const onSubmit = async (data) => {
        const { password, password_verify, reset_code, email } = data;
        const response = await execute("verify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                sessionId: sessionID,
                authorization: `Basic ${encode(`${username}:${password}`)}`,
            },
            body: JSON.stringify({
                reset_code,
                email,
                password_verify: encode(password_verify),
            }),
        });
        if (response?.entryDefects) {
            response.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else if (response?.session) {
            dispatch(setAssemblies({ SIGNUP_MISC: response.assembly }));
            dispatch(setUserInfo(response.session));
            trackGTM({
                event: `register`,
                action: "click",
                type: "Account Registration",
                category: "account",
            });
            dispatch(removeAssembly("CHANGE-EMAIL_REQUEST")); // Removing this assembly so that it will pull again.
            nextStep();
        }
    };

    return (
        <AssemblyProvider
            group="SIGNUP-PW_ESTABLISH_REQUEST"
            name="SIGNUP-PW_ESTABLISH_REQUEST"
            ignoreCache
        >
            {(assembly, assemblyIsLoading) => (
                <>
                    {assemblyIsLoading ? (
                        <div>Loading</div>
                    ) : (
                        <>
                            {verifyError && (
                                <Alert color="warning">
                                    Whoops, we had an issue verifying, please
                                    click the Verify button to try again.
                                </Alert>
                            )}
                            {resendError && (
                                <Alert color="warning">
                                    Whoops, we had an issue sending the code,
                                    please click the Resend Code link to try
                                    again.
                                </Alert>
                            )}
                            <UpdateEmail
                                isRegistering
                                callback={() => {
                                    reset();
                                }}
                            >
                                {({ toggleIsUpdating }) => (
                                    <>
                                        <div>
                                            <p>
                                                A code has been sent to your
                                                e-mail account. Please enter the
                                                code to verify your e-mail. If
                                                you no longer have access to
                                                this e-mail, click here to{" "}
                                                <Button
                                                    color="link"
                                                    className="m-0 p-0 d-inline"
                                                    onClick={() =>
                                                        toggleIsUpdating()
                                                    }
                                                >
                                                    {" "}
                                                    Update E-mail.
                                                </Button>
                                            </p>
                                        </div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <FormGroup className="mt-2">
                                                Verify e-mail address and create
                                                password.
                                            </FormGroup>
                                            <Row>
                                                <Col>
                                                    <ValidatingField
                                                        name="email"
                                                        assembly={assembly}
                                                        {...registerForm}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ValidatingField
                                                        name="reset_code"
                                                        assembly={assembly}
                                                        {...registerForm}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ValidatingField
                                                        name="password"
                                                        type="password"
                                                        assembly={assembly}
                                                        {...registerForm}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <ValidatingField
                                                        name="password_verify"
                                                        type="password"
                                                        assembly={assembly}
                                                        {...registerForm}
                                                    />
                                                </Col>
                                            </Row>
                                            <FormGroup className="d-flex">
                                                <IndeterminateButton
                                                    color="link"
                                                    className="ms-auto"
                                                    value="Resend Code"
                                                    isLoading={
                                                        isLoadingResetPassword
                                                    }
                                                    onClick={() => resendCode()}
                                                >
                                                    Resend Code
                                                </IndeterminateButton>
                                                <IndeterminateButton
                                                    type="submit"
                                                    className="btn btn-primary "
                                                    value="Verify"
                                                    isLoading={isLoading}
                                                >
                                                    Verify
                                                </IndeterminateButton>
                                            </FormGroup>
                                        </Form>
                                    </>
                                )}
                            </UpdateEmail>
                        </>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default Verify;
