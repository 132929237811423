import React from "react";
import AnonUser from "./AnonUser";
import AuthedUser from "./AuthedUser";
import { useSaveProperty } from "./hooks/useSaveProperty";

/**
 * This component will render a heart button that can be used to save a property.
 * When clicked, it will either add the property to the user's saved properties or remove it.
 * If the user is not logged in, the button will first open the login drawer.
 *
 * @param listingNumber {string} - The number of the property to be saved
 * @param location {string} - The location of the property to be saved
 * @param props {object} - Other props to pass to the button
 * @returns {React.ReactElement}
 * @example
 * <SaveProperty listingId="wamls-163875">
 *   Save Property
 * </SaveProperty>
 */
const SaveProperty = ({ listingNumber, location, ...props }) => {
    const { shouldAllowSave, setShouldSaveProperty, isSaved, save } =
        useSaveProperty({ listingNumber, location });

    return (
        <>
            {shouldAllowSave ? (
                <AuthedUser {...props} save={save} isSaved={isSaved} />
            ) : (
                <AnonUser
                    {...props}
                    setShouldSaveProperty={setShouldSaveProperty}
                />
            )}
        </>
    );
};

export default SaveProperty;
