import React from "react";
import { Spinner } from "reactstrap";

/**
 * Renders a loading indicator with a customizable label and spinner.
 *
 * @param {Object} props - Additional props to pass to the Spinner component.
 * @param {string} loadingLabel - Text label displayed next to the spinner.
 * @param {Object} style - Custom styles to apply to the container.
 * @param {string} color - Color of the spinner.
 * @return {JSX.Element} The rendered loading component.
 */
const Loading = ({
    loadingLabel = "Loading",
    style = {},
    color = "primary",
    ...props
}) => (
    <div
        className="d-flex align-items-center mt-3 justify-content-center"
        style={style}
        data-testid="loading"
    >
        <div className="me-2">{loadingLabel}</div>{" "}
        <Spinner color={color} {...props}>
            {""}
        </Spinner>
    </div>
);

export default Loading;
