import React from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import { useUser } from "../../../hooks/user";
import IndeterminateButton from "../../IndeterminateButton";
import Loading from "../../Loading";
import {
    removeAssembly,
    setAssemblies,
    updateSignupPwEstablishAssemblyEmail,
} from "../../../reducers/rootReducer";
import AssemblyProvider from "../../AssemblyProvider";

const Update = ({ cancel, verify, isRegistering }) => {
    const dispatch = useDispatch();
    const { execute, isLoading } = useUser();
    const registerForm = useForm();
    const {
        handleSubmit,
        setError,
        formState: { isDirty },
    } = registerForm;

    const onSubmit = async (data) => {
        const { email, email_verify } = data;
        const response = await execute("userID/email/change/confirm", {
            method: "POST",
            body: JSON.stringify({ email, email_verify }),
        });
        if (response.entryDefects) {
            response.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else {
            if (isRegistering) {
                const { email } = response.assembly.data;
                dispatch(updateSignupPwEstablishAssemblyEmail(email));
                dispatch(removeAssembly("CHANGE-EMAIL_REQUEST")); // Removing this assembly so that it will pull again.
            } else {
                dispatch(
                    setAssemblies({ "VERIFY-EMAIL_REQUEST": response.assembly })
                );
            }
            verify();
        }
    };

    return (
        <AssemblyProvider
            group="CHANGE-EMAIL_REQUEST"
            name="CHANGE-EMAIL_REQUEST"
        >
            {(assembly, isAssemblyLoading) => (
                <>
                    {isLoading && isAssemblyLoading && !assembly ? (
                        <Loading />
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        name="email"
                                        assembly={assembly}
                                        {...registerForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        name="email_verify"
                                        assembly={assembly}
                                        {...registerForm}
                                    />
                                </Col>
                            </Row>
                            <FormGroup className="d-flex">
                                <IndeterminateButton
                                    color="link"
                                    className="ms-auto"
                                    isLoading={isLoading}
                                    onClick={() => cancel()}
                                >
                                    Cancel
                                </IndeterminateButton>
                                <IndeterminateButton
                                    type="submit"
                                    className="btn btn-primary "
                                    value="Sign In"
                                    isLoading={isLoading}
                                    disabled={!isDirty}
                                >
                                    Update
                                </IndeterminateButton>
                            </FormGroup>
                        </Form>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default Update;
