import React, { useState } from "react";
import { AsyncSelect } from "../ReactSelect/ReactSelect";
import { components } from "react-select";
import { useHistory } from "react-router";
import { FaSearch } from "react-icons/fa";
import { parseSearchMatches } from "./helpers";
import { buildPath, debounce } from "../../support/helpers";
import { useSearchbox } from "../../hooks/useSearchbox";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import { omit } from "../../support/helpers";
import NoOptionsMessage from "../NoOptionsMessage";
import { stringify } from "query-string";

const Search = (props) => {
    const {
        callback,
        className,
        isMlsSearch,
        state_code,
        includes = "nicheItems,states,zips,mls",
        placeholder = "Enter lake, state, zip code or MLS#",
        nichetypePlural = "",
        locationOnPage,
    } = props;
    const history = useHistory();
    const { execute } = useSearchbox();
    const [selectRenderKey, setSelectRenderKey] = useState(0);
    const { trackGTM } = useTrackEvent();

    const handleClick = (path, state, region, type, label, searchTerm) => {
        let search;
        if (region) {
            const {
                more: { zip, search: searchData, distance },
            } = region;
            search = stringify({
                zip,
                search: searchData,

                range: distance,
            });
        }
        if (callback) callback();
        setSelectRenderKey(selectRenderKey + 1);

        trackGTM({
            event: "search",
            action: "click",
            type: "Searchbox",
            category: "general",
            includes,
            searchType: type,
            value: label,
            searchTerm,
            location: locationOnPage,
        });
        history.push({
            pathname: buildPath(path),
            state,
            search,
        });
    };

    const loadOptions = debounce((searchTerm, callback) => {
        if (searchTerm.length >= (isMlsSearch ? 3 : 2)) {
            execute(searchTerm, state_code, includes).then((response) => {
                callback(
                    response
                        ? parseSearchMatches({
                              supplementalInfo: response,
                              nicheLabel: nichetypePlural,
                              searchTerm,
                          })
                        : undefined
                );
            });
        }
    }, 250);

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <FaSearch />
            </components.DropdownIndicator>
        );
    };
    return (
        <AsyncSelect
            className={`${className} react-select`}
            key={`header-select-${selectRenderKey}`}
            {...omit(props, [
                "callback, includes, placeholder",
                "nichetypePlural",
                "locationOnPage",
                "className",
            ])}
            styles={{
                control: (provided) => ({
                    ...provided,
                    height: "100%",
                    width: "100%",
                }),
                container: (provided) => ({ ...provided, minWidth: "150px" }),
                placeholder: (provided) => ({
                    ...provided,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }),
                ...props.styles,
            }}
            placeholder={placeholder}
            loadOptions={loadOptions}
            onChange={({ value, type, label, searchTerm }) => {
                const {
                    more: {
                        path,
                        stateID,
                        nicheItemID,
                        listingID,
                        region,
                    } = {},
                } = value;
                handleClick(
                    path,
                    {
                        stateId: stateID,
                        nicheItemId: nicheItemID,
                        listingId: listingID,
                    },
                    region,
                    type,
                    label,
                    searchTerm
                );
            }}
            components={{
                DropdownIndicator,
                NoOptionsMessage: (props) => (
                    <NoOptionsMessage label={placeholder} {...props} />
                ),
            }}
            menuPortalTarget={props?.menuPortalTarget}
            onFocus={props?.onFocus}
        />
    );
};

export default Search;
