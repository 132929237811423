import React from "react";
import { Link } from "react-router-dom";

const Toast = ({ title, content }) => {
    return (
        <div>
            {title && <div>{title}</div>}
            <p className="mb-0">{content}</p>
            <div className="d-flex justify-content-end">
                <Link to="/account/properties">Go to Saved Properties</Link>
            </div>
        </div>
    );
};

export default Toast;
