import React from "react";
import { Button } from "reactstrap";
import { FaRegHeart } from "react-icons/fa";
import { useAnonUser } from "./hooks/useAnonUser";

/**
 * This component will render an empty heart button for anonymous users.
 * When clicked it will prompt the user to log in.
 *
 * @param children {React.ReactNode} - The content of the button
 * @param heartClassName {string} - The class name of the heart icon
 * @param props {object} - Other props to pass to the button
 * @param setShouldSaveProperty {Function} - A function that sets whether the user should save the property
 * @returns {React.ReactElement}
 */
const AnonUser = ({
    children,
    heartClassName = "",
    setShouldSaveProperty,
    ...props
}) => {
    const { handleClick } = useAnonUser({ setShouldSaveProperty });

    return (
        <Button {...props} onClick={() => handleClick()}>
            <FaRegHeart
                className={heartClassName}
                style={{
                    filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 4px)",
                }}
            />
            {children && children(false)}
        </Button>
    );
};

export default AnonUser;
