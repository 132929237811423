import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce";

/**
 * Handles the save and unsave logic for the save property button on
 * listing cards and the listing details page.
 *
 * @param save {Function} - The save function to be called
 * @param isSaved {boolean} - Whether the property is saved or not
 * @param debounceDelay {number} - The delay to debounce the save action
 * @returns {{isSaved: boolean, handleClick: Function}} - isSaved and handleClick
 */
export const useAuthedUser = ({
    save,
    isSaved: isSavedProp,
    debounceDelay = 300,
}) => {
    const [isSaved, setIsSaved] = useState(isSavedProp);
    const debouncedInputValue = useDebounce(isSaved, debounceDelay);
    const preventInitialSave = useRef(true);

    // This is the save action
    // Debouncing this prevents a spam click and will only save once
    // The user has stopped clicking for 300ms
    useEffect(() => {
        if (!preventInitialSave.current) {
            const makeSave = async () => {
                const action = debouncedInputValue ? "save" : "delete";
                const error = await save(action);
                if (error) {
                    preventInitialSave.current = true;
                    setIsSaved(!isSaved);
                }
            };
            makeSave();
        }
        preventInitialSave.current = false;
    }, [debouncedInputValue]);

    const handleClick = () => {
        setIsSaved(!isSaved);
    };

    return {
        isSaved,
        handleClick,
    };
};
