import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PubSub from "pubsub-js";
import { Button } from "reactstrap";
import { Link, matchPath, useLocation } from "react-router-dom";
import { FaPhone } from "react-icons/fa";
import ItemCard from "../ItemCard";
import SocialBar from "../SocialBar";
import { setMode } from "../../reducers/rootReducer";
import NavLink from "../Header/NavLink";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import CdnImage from "../CdnImage";
import { getPageType } from "../../support/helpers";
import { prependDomain } from "../../support/helpers";
import Divider from "../Divider";
import styled from "styled-components";

const MobileFooter = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { params } = matchPath(pathname, {
        path: "/:state?/:niche?/:three?",
    });
    const isHomePage = getPageType(params, "index");
    const styling = useSelector((state) => state.root.styling);
    const mode = useSelector((state) => state.root.mode);
    const serverOrigin = useSelector((state) => state.root.serverOrigin);
    const { trackEvent, trackGTM } = useTrackEvent();
    const {
        nicheName,
        nichePhoneNumber,
        myNicheCopyright,
        microNav,
        microCreditsAndDisclaimers,
    } = styling || {};
    const navItems = microNav?.footer;
    const { equalHousingOpportunity, listingsExcluded, mappingService } =
        microCreditsAndDisclaimers || {};

    const getLabel = (navItem) => navItem.mobileLabel || navItem.label;

    const handleClick = () => {
        // We want to clear the search when the user visits the state page.
        PubSub.publish("RESET_SEARCH");
        if (mode === "maintenance") {
            dispatch(setMode("normal"));
        }
    };

    if (!navItems) return null;

    let origin = serverOrigin;
    if (typeof window !== "undefined" && window !== null) {
        origin = window.origin;
    }

    return (
        <div className="text-white container my-4">
            <Link className="mt-2 d-block" to="/" onClick={handleClick}>
                <CdnImage
                    src="/logo-white.png"
                    style={{ height: "40px" }}
                    alt={nicheName}
                />
            </Link>
            {navItems.map((navBlock, i) => (
                <div key={`footer-nav-block-${i}`}>
                    <ItemCard
                        className="bg-transparent text-white rounded-0 py-2 border-top-0 border-start-0 border-end-0 border-white"
                        titleClassName="text-white title "
                        iconClassName="text-white"
                        title={getLabel(navBlock)}
                    >
                        <div className="ms-4">
                            {Array.isArray(navBlock.children) &&
                                navBlock.children.map((item, i) => (
                                    <NavLink
                                        key={`footer-${
                                            getLabel(navBlock).toLocaleLowerCase
                                        }-list-${i}`}
                                        className="d-block text-white py-2"
                                        to={{
                                            pathname: prependDomain(
                                                origin,
                                                item.link
                                            ),
                                            state: { stateId: item?.stateCode },
                                        }}
                                        onClick={handleClick}
                                    >
                                        {getLabel(item)}
                                    </NavLink>
                                ))}
                        </div>
                    </ItemCard>
                </div>
            ))}
            <div>
                <Button
                    color="link"
                    className="d-flex align-items-cetner m-0 px-0 text-white"
                    onClick={() => {
                        trackGTM({
                            event: `phoneClick`,
                            action: "click",
                            type: "Footer Phone",
                            category: "contact",
                            button_label: nichePhoneNumber,
                            value: nichePhoneNumber,
                        });
                        trackEvent("ACTIONS", {
                            CATEGORY: "event",
                            ASSOCIATE: "Footer Phone",
                            ACTION: "click",
                            SOURCE_INFO: {
                                eventLabel: nichePhoneNumber,
                                eventValue: nichePhoneNumber,
                            },
                        });
                    }}
                    href={`tel:${nichePhoneNumber}`}
                >
                    <div>
                        <FaPhone
                            className="me-2"
                            size="12"
                            style={{ marginTop: "-4px" }}
                        />
                    </div>
                    <div>{nichePhoneNumber}</div>
                </Button>
                <SocialBar className="text-white ms-2" />
                <div className="mt-4 small text-wrap">
                    {equalHousingOpportunity && (
                        <p className="mb-2">
                            <CdnImage
                                src={equalHousingOpportunity.image}
                                isGlobalImage
                                className="float-start me-2"
                                alt={nicheName}
                            />
                            {equalHousingOpportunity.text}
                        </p>
                    )}
                    {!isHomePage && (
                        <>
                            {listingsExcluded && (
                                <p className="mb-2">{listingsExcluded.text}</p>
                            )}
                            {mappingService && (
                                <p className="mb-2">{mappingService.text}</p>
                            )}
                        </>
                    )}
                </div>
                <div className="mt-2 small text-wrap">
                    <div
                        dangerouslySetInnerHTML={{ __html: myNicheCopyright }}
                    />
                </div>
                {microNav?.privacy && (
                    <div className="mt-2 d-flex flex-wrap">
                        {microNav.privacy.map((item, i) => (
                            <Divider key={`${item.label}-${i}`}>
                                <Button
                                    color="link"
                                    href={prependDomain(origin, item?.link)}
                                    className={`text-white p-0 m-0 border-0 rounded-0 ${
                                        i > 0 && "ps-1"
                                    }`}
                                >
                                    <p
                                        className="mb-0 text-nowrap"
                                        style={{ fontSize: ".77rem" }}
                                    >
                                        {item.label}
                                    </p>
                                </Button>
                            </Divider>
                        ))}
                    </div>
                )}
                {microNav?.outlying && (
                    <CookieSettings>
                        {microNav?.outlying.map(
                            (
                                {
                                    label,
                                    class: className,
                                    style,
                                    onClick,
                                    ...props
                                },
                                i
                            ) => (
                                <Button
                                    color="link"
                                    key={`${label}-${i}`}
                                    className={`text-white p-0 m-0 mt-2 d-block text-start ${
                                        className ? className : ""
                                    }`}
                                    style={{ fontSize: ".77rem" }}
                                    onClick={() =>
                                        onClick ? eval(onClick) : ""
                                    }
                                    {...props}
                                >
                                    {label}
                                </Button>
                            )
                        )}
                    </CookieSettings>
                )}
            </div>
        </div>
    );
};

const CookieSettings = styled.div`
    #ot-sdk-btn.ot-sdk-show-settings {
        border: none;
    }
    #ot-sdk-btn.ot-sdk-show-settings:hover {
        background-color: transparent;
    }
`;

export default MobileFooter;
