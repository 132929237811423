import { parse } from "query-string";
import { searchQueryModel } from "../components/Search/SearchProvider";

const isZipCode = (val) => RegExp("^[0-9]{5}(?:-[0-9]{4})?$").test(val);

const handleLocationSearch = (url, shouldRedirect = false) => {
    if (url.search) {
        const parts = url.pathname.split("/");
        parts.shift(); //removing empty first item
        const isState = parts.length === 1;
        if (isState) {
            // This means we should be doing a geo search
            const searchQuery = parse(url.search);
            const { zip, search } = searchQuery;
            // If a search is a zip code, we need to set the 'search' param to the zip
            if (!zip && isZipCode(search)) {
                url.searchParams.append("zip", search);
                // If there is no zip and search is not a zip, remove the search.
            } else if (!zip && !isZipCode(search)) {
                url.searchParams.delete("search");
            }

            // If there is no search/zip, we cannot do a search at all. Remove other params for a search.
            if (!zip && !search) {
                Object.keys(searchQuery).forEach((key) => {
                    if (Object.keys(searchQueryModel).includes(key)) {
                        url.searchParams.delete(key);
                    }
                });
            }
        }
        return shouldRedirect ? url : undefined;
    }
    return undefined;
};

const searchUrlParser = (rawurl, base = "http://imadummyurl.com") => {
    const regExOnlyNumbers = RegExp("^[0-9]*$");
    // Splitting path parts and query params.
    const [url, initialParams] = rawurl.split("?");
    const parts = url.split("/");
    //removing empty first item
    parts.shift();
    // Removing last item if there is a traling slash
    if (url.endsWith("/")) {
        parts.pop();
    }
    const isSearch = parts.length > 5; // Greater than 5 parts --> User Search
    const isStateRegion =
        parts.length === 3 &&
        regExOnlyNumbers.test(parts[1]) &&
        regExOnlyNumbers.test(parts[2]); // State Region Check
    if (isSearch || isStateRegion) {
        let path;
        const isGeoSearch = regExOnlyNumbers.test(parts[1]);
        const state = parts.shift();
        const search = initialParams ? `?${initialParams}` : "";
        if (isGeoSearch) {
            path = new URL(`/${state}${search}`, base);
            const zip = parts.shift();
            path.searchParams.append("search", zip);
            path.searchParams.append("zip", zip);
            path.searchParams.append("range", parts.shift());
        } else {
            const nicheItem = parts.shift();
            let development = "";
            if (parts[0] && !regExOnlyNumbers.test(parts[0])) {
                development = `/${parts.shift()}`;
            }
            path = new URL(
                `/${state}/${nicheItem}${development}${search}`,
                base
            );
        }
        // /alabama/guntersville-lake/1/200000/250000/0/0/Featured/PropAny/M0/all

        if (path) {
            const page = parts.shift();
            if (page && parseInt(page) > 1)
                path.searchParams.append("page", page);
            const price_min = parts.shift();
            if (price_min && price_min !== "0")
                path.searchParams.append("price_min", price_min);
            const price_max = parts.shift();
            if (price_max && price_max !== "0")
                path.searchParams.append("price_max", price_max);
            const bedrooms = parts.shift();
            if (bedrooms && bedrooms !== "0")
                path.searchParams.append("bedrooms", bedrooms);
            const bathrooms = parts.shift();
            if (bathrooms && bathrooms !== "0")
                path.searchParams.append("bathrooms", bathrooms);
            const sort_order = parts.shift();
            if (sort_order) path.searchParams.append("sort_order", sort_order);
            const property_type = parts.shift();
            if (property_type && property_type !== "PropAny")
                path.searchParams.append("property_type", property_type);
            const mega = parts.shift();
            if (mega && mega.startsWith("i")) {
                const megaArray = mega.split("i");
                megaArray.shift(); // removing first empty string
                path.searchParams.append("mega", megaArray.join("_"));
            }
            const waterfront = parts.shift();
            if (waterfront === "1") path.searchParams.append("waterfront", "1");
        }
        return handleLocationSearch(path, true);
        // State Region check
    } else if (
        parts.length > 1 &&
        regExOnlyNumbers.test(parts[parts.length - 1])
    ) {
        const page = parts.pop();
        const path = new URL(`/${parts.join("/")}`, base);
        // only add the page number for non-state pages
        if (parts.length > 1) path.searchParams.append("page", page);
        return path;
    }
    return handleLocationSearch(new URL(rawurl, base), true);
};

export default searchUrlParser;
