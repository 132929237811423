import { publish } from "pubsub-js";
import { useSelector } from "react-redux";

/**
 * Custom hook for the anonymous user save property button.
 *
 * @param setShouldSaveProperty {function} - sets the callback function to save the property after login
 */
export const useAnonUser = ({ setShouldSaveProperty }) => {
    const isMobile = useSelector((state) => state.root.isMobile);
    const handleClick = () => {
        if (isMobile) {
            publish("TOGGLE_MOBILE_DRAWER", () => {
                setShouldSaveProperty(true);
            });
        } else {
            publish("TOGGLE_ACCOUNT_DRAWER", () => {
                setShouldSaveProperty(true);
            });
        }
    };

    return {
        handleClick,
    };
};
