import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useActivityTracking } from "../hooks/useActivityTracking";
import { isObject } from "../support/helpers";
import useExecuteScript from "./useExecuteScript";

export const useTrackEvent = () => {
    const trackingItems = useSelector(
        (state) => state.root?.styling?.trackingInfo
    );
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const isMobile = useSelector((state) => state.root.isMobile);
    const { execute } = useActivityTracking();
    const runJs = useExecuteScript();

    const trackEvent = (eventKey, payload) => {
        if (trackingItems) {
            const itemsToTrack =
                trackingItems.filter((item) => item.key === eventKey) || [];
            if (itemsToTrack.length > 0) {
                const trackingToCall = [];
                itemsToTrack.forEach((item) => {
                    let trackingCode = item.content;
                    Object.keys(payload).forEach((key) => {
                        if (typeof payload[key] === "string") {
                            trackingCode = trackingCode.replace(
                                `|${key}|`,
                                payload[key]
                            );
                        } else {
                            trackingCode = trackingCode.replace(
                                `'|${key}|'`,
                                JSON.stringify(payload[key])
                            );
                        }
                    });
                    trackingToCall.push(trackingCode);
                });
                trackingToCall.forEach((trackingScript) =>
                    runJs(trackingScript)
                );
            }
            // Sending item event to our DB
            execute({ category: eventKey, action: payload });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const trackGTM = (payload) => {
        if (window.dataLayer && isObject(payload)) {
            // We build the base Data Layer data here,
            // then add context from the payload.
            // TODO: Add Niche/State/Listing IDs and names to base
            window.dataLayer.push({
                user: {
                    isLoggedIn,
                },
                page: {
                    pathname: window?.location?.pathname,
                    title: document?.title,
                    isMobile,
                },
                ...payload,
            });
        } else if (
            process.env.NODE_ENV !== "production" &&
            process.env.REACT_APP_SHOW_TRACKING_LOGGING
        ) {
            console.log("GTM Tracking", payload);
        }
    };

    return {
        trackEvent: useCallback(trackEvent, [trackingItems, execute]),
        trackGTM,
    };
};
