import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, FormGroup, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import { useUser } from "../../../hooks/user";
import IndeterminateButton from "../../IndeterminateButton";
import { setUserInfo } from "../../../reducers/rootReducer";
import AssemblyProvider from "../../AssemblyProvider";
import { publish } from "pubsub-js";
import { userEndpoints } from "../../../services/userEndpoints";

const Complete = () => {
    const dispatch = useDispatch();
    const registerForm = useForm({ mode: "onBlur" });
    const {
        handleSubmit,
        setError,
        formState: { isDirty },
    } = registerForm;
    const { execute, isLoading, error } = useUser();
    const isMobile = useSelector((state) => state.root.isMobile);

    const closeDrawer = () => {
        if (isMobile) {
            publish("TOGGLE_MOBILE_DRAWER");
        } else {
            publish("TOGGLE_ACCOUNT_DRAWER");
        }
    };

    const onSubmit = async (data) => {
        if (isDirty) {
            const response = await execute("userId/complete-registration", {
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response?.entryDefects) {
                response.entryDefects.entryDefects.forEach((defect) => {
                    const name = Object.keys(defect)[0];
                    setError(name, { message: defect[name] });
                });
            } else if (response?.session) {
                dispatch(userEndpoints.util.invalidateTags(["User"]));
                dispatch(setUserInfo(response.session));
                closeDrawer();
            }
        } else {
            closeDrawer();
        }
    };

    return (
        <AssemblyProvider group="SIGNUP_MISC" name="SIGNUP_MISC">
            {(assembly) => (
                <>
                    {error && (
                        <Alert color="warning">
                            <h4>Sorry, something went wrong</h4>
                            <div>
                                Some of your information may not have saved
                                properly. Please click submit to try again or{" "}
                                <Link onClick={() => onSubmit()}>
                                    click here
                                </Link>{" "}
                                to check your account settings.
                            </div>
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="phone"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="street"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="city"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                            <Col>
                                <ValidatingField
                                    name="state_id"
                                    type="select"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                            <Col>
                                <ValidatingField
                                    name="postal_code"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="user_category"
                                    type="select"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                            <IndeterminateButton
                                type="submit"
                                className="btn btn-primary w-100"
                                value="Sign In"
                                isLoading={isLoading}
                            >
                                {isDirty && "Update & "} Finish
                            </IndeterminateButton>
                        </FormGroup>
                    </Form>
                </>
            )}
        </AssemblyProvider>
    );
};

export default Complete;
